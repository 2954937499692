import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import Faqs from './Faqs';
import {
  Navbar, Button, Footer, AnimatedSection
} from '../../components';

import coinsVideo from '../../assets/images/coins.mp4';
// import bambooLogo from '../../assets/images/bamboo-logo-grey.png';
// import troveLogo from '../../assets/images/trove-logo-grey.png';
// import dashboardOnPhone from '../../assets/images/carrot-dashboard-phone.png';
// import cashAndBarChart from '../../assets/images/cash-bar-chart.png';
import carrotDashboardOnPc from '../../assets/images/carrot-dashboard-pc-screen.png';
import lighteningIcon from '../../assets/images/lightening-icon.svg';
import serverIcon from '../../assets/images/server-icon.svg';
import lockIcon from '../../assets/images/lock-icon.svg';
import creditLine from '../../assets/images/credit-line.png';
import lowerRateIcon from '../../assets/images/lower-rate-icon.svg';
import higherRateIcon from '../../assets/images/higher-rate-icon.svg';
import flexibleIcon from '../../assets/images/flexible-icon.svg';
import tewntyKBold from '../../assets/images/twenty-k-bold.png';

import './index.css';
import './mobile.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  state = {
    modalShow: false,
  }

  handleModal = modalState => {
    this.setState({ modalShow: modalState });
  }

  handleClick() {
    const { button } = this.state;
    this.setState({
      button: !button,
    });
  }

  render() {
    const { modalShow, button } = this.state;

    return (
      <div id="home">
        <Navbar />

        {/* section1 */}
        <AnimatedSection>
          <section id="home__section-1">
            <div className="title">
              <div>
                Get Instant Credit
              </div>
              <div>
                without the
                {' '}
                <span>hassle.</span>
              </div>
            </div>
            <div className="text">
              <div>
                We helps businesses and their customers access a line of credit collaterized by
                their assets (Stocks, Cash & Crypto)
              </div>
            </div>
            <div className="button-box">
              <Link style={{ textDecoration: 'none' }} to="/signup">
                <Button cta>
                  Start using Carrot
                </Button>
              </Link>
            </div>
            <div className="animation-box mobile-display-none">
              <video autoPlay loop muted className="animation">
                <source src={coinsVideo} type="video/mp4" />
                Your browser does not support this feature.
              </video>
            </div>
            {/* <div className="text-2">
              In business with leaders in the investment industry
            </div>
            <div className="partners-array">
              <img src={bambooLogo} alt="Bamboo logo" style={{ height: '19px' }} />
              <img src={troveLogo} alt="Trove logo" />
            </div> */}
          </section>
        </AnimatedSection>

        {/* section-2 */}
        <AnimatedSection>
          <section id="home__section-2">
            <div className="title">
              <div>
                Accessing a line of credit is
                {' '}
                <span>seamless</span>
                {' '}
                and
                {' '}
                <span>straightforward.</span>
              </div>
            </div>

            <Carousel className="cards" id="cardsMobile">
              <Carousel.Item>
                <div className="card left-card">
                  <div className="title">
                    Access to Liquidity
                  </div>
                  <div className="text">
                    Individuals can collateralize a diverse range of assets, including stocks,
                    cash, and cryptocurrency. This allows for greater flexibility and ensures
                    that businesses can access credit without the need for traditional forms of collateral.
                  </div>
                  <div className="cta-text">
                    <Link style={{ textDecoration: 'underline' }} to="/signup">
                      Create an Account Now
                    </Link>
                  </div>
                  {/* <img className="mobile-display-none" src={dashboardOnPhone} alt="carrot dashboard on phone" /> */}
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card right-card">
                  <div className="title">
                    Preserve Ownership
                  </div>
                  <div className="text">
                    Businesses can collateralize a diverse range of assets, including stocks,
                    cash, and cryptocurrency. This allows for greater flexibility and ensures
                    that businesses can access credit without the need for traditional forms of collateral.
                  </div>
                  <div className="cta-text">
                    <Link style={{ textDecoration: 'underline' }} to="/signup">
                      See how it works
                    </Link>
                  </div>
                  {/* <img className="mobile-display-none" src={cashAndBarChart} alt="cash and bar chart" /> */}
                </div>
              </Carousel.Item>
            </Carousel>

            <div className="cards" id="cardsWeb">
              <div className="card left-card">
                <div className="title">
                  Access to Liquidity
                </div>
                <div className="text">
                  Individuals can collateralize a diverse range of assets, including stocks,
                  cash, and cryptocurrency. This allows for greater flexibility and ensures
                  that businesses can access credit without the need for traditional forms of collateral.
                </div>
                <div className="cta-text">
                  <Link style={{ textDecoration: 'underline' }} to="/signup">
                    Create an Account Now
                  </Link>
                </div>
                {/* <img className="mobile-display-none" src={dashboardOnPhone} alt="carrot dashboard on phone" /> */}
              </div>

              <div className="card right-card">
                <div className="title">
                  Preserve Ownership
                </div>
                <div className="text">
                  Businesses can collateralize a diverse range of assets, including stocks,
                  cash, and cryptocurrency. This allows for greater flexibility and ensures
                  that businesses can access credit without the need for traditional forms of collateral.
                </div>
                <div className="cta-text">
                  <Link style={{ textDecoration: 'underline' }} to="/">
                    See how it works
                  </Link>
                </div>
                {/* <img className="mobile-display-none" src={cashAndBarChart} alt="cash and bar chart" /> */}
              </div>
            </div>
          </section>
        </AnimatedSection>

        {/* section-3 */}
        <AnimatedSection>
          <section id="home__section-3">
            <div className="title">
              We offer flexible credit lines tailored to the unique needs of each business.
            </div>

            <div className="large-card">
              <div className="left-content">
                <div className="title">
                  Empowering businesses
                </div>
                <div className="text">
                  Don&apos;t let financial constraints hold your business back. Take the first step towards accessing flexible,
                  collateralized credit solutions with Carrot today. Our team is standing by to help you explore your
                  options and find the right financing solution for your business&apos;s unique needs.
                </div>
                <div className="cta-text">
                  <Link style={{ textDecoration: 'underline' }} to="/signup">
                    Create an Account Now
                  </Link>
                </div>
              </div>

              <div className="right-img">
                <img src={carrotDashboardOnPc} alt="Carrot dashboard pc screen" />
              </div>
            </div>
          </section>
        </AnimatedSection>

        {/* section-4 */}
        <AnimatedSection>
          <section id="home__section-4">
            <div className="pre-title">
              WHY CARROT
            </div>
            <div className="title">
              <div>
                Finally, a credit line
              </div>
              <div>
                <span>collaterized by your assets</span>
              </div>
            </div>
            <div className="cards">
              <div className="card">
                <div className="image-box">
                  <img src={lighteningIcon} alt="lightening icon" />
                </div>
                <div className="title">
                  <div className="title-bar" />
                  Fast
                </div>
                <div className="text">
                  We prioritize fast funding, ensuring businesses seize opportunities without delay.
                </div>
              </div>
              <div className="card">
                <div className="image-box">
                  <img src={lockIcon} alt="lock icon" />
                </div>
                <div className="title">
                  <div className="title-bar" />
                  Secure
                </div>
                <div className="text">
                  Securely collateralize stocks, cash, and crypto for flexible credit access.
                </div>
              </div>
              <div className="card">
                <div className="image-box">
                  <img src={serverIcon} alt="server icon" />
                </div>
                <div className="title">
                  <div className="title-bar" />
                  Simple
                </div>
                <div className="text">
                  Streamlined online credit applications simplify borrowing, eliminating paperwork and delays.
                </div>
              </div>
              <div className="card">
                <div className="image-box">
                  <img src={serverIcon} alt="server icon" />
                </div>
                <div className="title">
                  <div className="title-bar" />
                  Built for scale
                </div>
                <div className="text">
                  Flexible credit lines tailored to each business&apos;s needs, short-term or long-term.
                </div>
              </div>
            </div>
          </section>
        </AnimatedSection>

        {/* section-5 */}
        <AnimatedSection>
          <section id="home__section-5">
            <div className="left-content mobile-display-none">
              <img src={creditLine} alt="Carrot as a credit line" />
            </div>
            <div className="right-content">
              <div className="title">
                We offer lower interest rates than unsecured loans due to reduced risk for lenders.
              </div>
              <div className="cards">
                <div className="card">
                  <img src={lowerRateIcon} alt="lower rates icon" />
                  <div className="title">
                    Lower Interest Rates
                  </div>
                  <div className="text">
                    Collateralized credit typically offers lower interest rates compared to unsecured loans,
                    thanks to the reduced risk for lenders. This can result in significant cost savings for your
                    business over the long term.
                  </div>
                </div>
                <div className="card">
                  <img src={flexibleIcon} alt="flexible icon" style={{ width: '18px' }} />
                  <div className="title">
                    Flexible Terms
                  </div>
                  <div className="text">
                    Collateralized credit offers more flexibility in terms of loan amounts,
                    repayment schedules, and interest rates, allowing businesses to customize
                    their financing to suit their specific needs and circumstances.
                  </div>
                </div>
                <div className="card">
                  <img src={higherRateIcon} alt="higher approval icon" />
                  <div className="title">
                    Higher Approval Rates
                  </div>
                  <div className="text">
                    By providing collateral, businesses can improve their chances of loan approval,
                    even if they have limited credit history or less-than-perfect credit scores.
                  </div>
                </div>
              </div>
            </div>
          </section>
        </AnimatedSection>

        {/* section-6 */}
        <AnimatedSection>
          <section id="home__section-6">
            <div className="large-card">
              <div className="left-content">
                <div className="title">
                  Our users are getting up to
                  {' '}
                  <span>$20k</span>
                  {' '}
                  in credit!
                </div>
                <div className="text">
                  Our team is standing by to help you explore your options and find the right financing solution
                  for your business&apos;s unique needs.
                  <br />
                  <br />
                  Apply now and embark on a journey of growth, innovation, and success with Carrot by your side.
                </div>
                <div className="cta-text">
                  <Link style={{ textDecoration: 'underline' }} to="/signup">
                    Create an Account Now
                  </Link>
                </div>
              </div>

              <div className="right-img">
                <img src={tewntyKBold} alt="Carrot dashboard pc screen" />
              </div>
            </div>
          </section>
        </AnimatedSection>

        <AnimatedSection>
          <section id="home__faq-section">
            <div className="title">
              Frequently Asked Questions
            </div>
            <Faqs />
          </section>
        </AnimatedSection>

        <AnimatedSection>
          <section id="home__section-7">
            <div id="issue">
              Having any issues with your account or getting started.
              {' '}
              <span>Talk to us</span>
            </div>
            <div id="button-box" onClick={() => this.handleModal(true)}>
              <Button cta>
                Contact Us
              </Button>
            </div>
          </section>
        </AnimatedSection>

        <AnimatedSection>
          <Footer />
        </AnimatedSection>

        <div id="modalBehavior">
          <Modal
            show={modalShow}
            onHide={() => this.handleModal(false)}
            size="lg"
            backdrop="static"
            id="modal"
          >
            <Modal.Header closeButton id="modal-header">
              Send us a message
            </Modal.Header>
            <Modal.Body id="modal-body">
              <h4>Account Type</h4>

              <div id="choose">
                <div className={button ? 'buttonTrue' : ''} id="chooseIndividual" onClick={this.handleClick}>Individual</div>
                <div id="chooseBusiness" className={button ? '' : 'buttonFalse'} onClick={this.handleClick}>Business</div>
              </div>

              <Container>
                <Row>
                  <Form>
                    <div id="form">
                      <Col xs={6} md={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label id="form-label">First Name</Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            id="form-control"
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6} md={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label id="form-label">Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            id="form-control"
                          />
                        </Form.Group>
                      </Col>
                    </div>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label id="form-label2">Email</Form.Label>
                      <Form.Control
                        type="email"
                        autoFocus
                        id="form-control2"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label id="form-label2">Message</Form.Label>
                      <Form.Control
                        type="text"
                        autoFocus
                        id="form-control2"
                      />
                    </Form.Group>
                  </Form>
                </Row>
              </Container>

              <div id="button-box" onClick={() => this.handleModal(true)}>
                <Button cta>
                  Submit
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Home;
