import React from 'react';

import { Page, Button, MultiSelect } from '../components';
// import creditLifeLine from '../assets/images/credit-lifetime.png';
import waitlistImg from '../assets/images/waitlist.png';

import * as Helpers from '../helpers';

class Waitlist extends React.Component {
  state = {
    app: {
      backgroundColor: undefined,
      banner: undefined,
      fontColor: undefined,
      icon: undefined,
      name: undefined,
    },
  }

  componentDidMount() {
    const { getUserApplications, user: { user_id }, application: { user: applications } } = this.props;
    const application_id = Helpers.token.get('user:application_id');
    if (applications.length <= 0) {
      getUserApplications(
        user_id,
        res => {
          this.updateApp(res.data.applications, application_id);
        }
      );
    } else {
      this.updateApp(applications, application_id);
    }
  }

  updateApp = (applications, application_id, callback) => {
    const application = applications?.filter(vendor => Number(vendor.application_id) === Number(application_id))[0];
    this.setState({
      app: {
        backgroundColor: application?.images.backgroundColor,
        banner: application?.images.banner,
        fontColor: (application?.images?.backgroundColor).toLowerCase() === '#ffffff' ? 'black' : 'white',
        icon: application?.images.icon,
        name: application?.name,
      },
    },
    () => {
      if (callback) {
        callback();
      }
    });
  }

  back = () => {
    const { logout, history } = this.props;
    logout(history);
  }

  switchPortfolio = application_id => {
    Helpers.token.set(application_id, 'user:application_id');
    const { history, application: { user: applications }, portfolio: { all: portfolioArray } } = this.props;
    this.updateApp(
      applications,
      application_id,
      () => {
        const waitlist = Number(application_id) === 0
          ? '0'
          : portfolioArray.filter(portfolio => Number(portfolio.application.application_id) === Number(application_id))[0]?.waitlist;
        if (waitlist === '0') {
          history.push('/dashboard');
        }
      }
    );
  }

  render() {
    const { user: { loading }, application: { user: applications } } = this.props;
    const { app } = this.state;

    return (
      <Page
        title="Waitlist"
        alignContentMobile="align-center"
        back={this.back}
        background="illustration-2"
        hasHeader
        hasLogo
        hm="35px 0 0 0"
        footer={<></>}
        dropdown={(
          <MultiSelect key="waitlist-multiselect" switchPortfolio={this.switchPortfolio} app={app} applications={applications} size="small" />
        )}
      >
        <div className="waitlist-multiselect-web mobile-display-none">
          <MultiSelect key="waitlist-multiselect" switchPortfolio={this.switchPortfolio} app={app} applications={applications} size="big" />
        </div>

        <div className="waitlist-image">
          <img src={waitlistImg} alt="waitlist" />
        </div>

        <div className="waitlist-text">
          <div className="waitlist-text-heading">
            Coming Soon: Flexible Loans at low interest rates by Carrot
          </div>

          <div className="waitlist-text-body">
            Thank you for clicking on our offer. We have added you to our waitlist and will notify you as soon as we can serve your requests.
          </div>
        </div>

        <div className="waitlist-button">
          <Button
            onClick={this.back}
            size="large"
            background="light"
            loading={loading.some(url => url === '')}
          >
            CLOSE
          </Button>
        </div>
      </Page>
    );
  }
}

export default Waitlist;
