import * as Helpers from '../../helpers';

import { ERROR, LOADING } from './user';

export const FETCH_ALL_NOTIFICATIONS = 'FETCH_ALL_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export const getNotifications = (limit, offset, success) => Helpers.api(
  `/notifications?limit=${limit || 10}&offset=${offset || 0}`,
  'GET',
  null,
  { success },
  { error: ERROR, loading: LOADING, responder: FETCH_ALL_NOTIFICATIONS }
);

// export const clearNotifications = () => dispatch => {
//   dispatch({ type: FETCH_ALL_NOTIFICATIONS });
// };

export const markNotificationsAsRead = (notification_id, success, error) => Helpers.api(
  '/notifications',
  'PUT',
  { notification_id },
  { error, success },
  { error: ERROR, loading: LOADING, responder: UPDATE_NOTIFICATIONS }
);
