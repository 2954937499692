import React from 'react';
import jwtDecode from 'jwt-decode';
import { countries } from 'countries-list';
import libphonenumber from 'google-libphonenumber';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiInfo } from 'react-icons/fi';

import {
  Button, Page, Input, Successful, Tabs, DoubleInput
} from '../../components';

import * as Helpers from '../../helpers';

import tickCircle from '../../assets/images/tick-circle-green.svg';
import cancel from '../../assets/images/cancel.svg';

const countriesKeys = Object.keys(countries);
class Forgot extends React.Component {
  state = {
    activeTab: 0,
    callingCode: '234',
    code: '',
    confirmPassword: '',
    countryCode: 'NG',
    email: '',
    emailOrMobile: '',
    mobile: '',
    otp: '',
    otpTime: -1,
    password: '',
    show: {
      otpInput: false,
      passwordInput: false,
      success: false,
    },
    showPassword: false,
  }

  componentDidMount = () => {
    if (Helpers.token.get('user:token')) {
      const { mobile, email } = jwtDecode(Helpers.token.get('user:token'));
      this.setState({ email, mobile });
    }
  }

  change = type => e => this.setState({ [type]: e.target.value });

  show = (comp, option, value) => () => {
    const { show } = this.state;
    this.setState({
      [option]: value,
      show: {
        ...show,
        ...Object.keys(show).reduce((prev, cur) => ({ ...prev, [cur]: false }), {}),
        [comp]: true,
      },
    });
  }

  sendOtp = () => {
    const {
      callingCode, emailOrMobile, activeTab, mobile, email,
    } = this.state;
    const emailOrMobileTrim = emailOrMobile.trim();
    if (!emailOrMobileTrim) {
      Helpers.notification.error(`Enter your ${activeTab === 0 ? 'mobile number' : 'email'} to continue`);
    } else if (mobile || email) {
      if (activeTab === 0) {
        const finalEmailOrMobile = `${callingCode}${emailOrMobileTrim}`;
        if (finalEmailOrMobile === mobile) {
          const { otp } = this.props;
          otp(
            `+${mobile}`,
            'mobile',
            () => {
              this.startOtpTimer();
              this.show('otpInput')();
              Helpers.notification.success('Your OTP has been sent.', 'Sent!');
            },
            () => {}
          );
        } else {
          Helpers.notification.error('Incorrect mobile number');
        }
      } else if (emailOrMobileTrim === email) {
        const { otp } = this.props;
        otp(
          emailOrMobileTrim,
          'email',
          () => {
            this.show('otpInput')();
            Helpers.notification.success('Your OTP has been sent.', 'Check your email');
          },
          () => {}
        );
      } else {
        Helpers.notification.error('Incorrect email');
      }
    } else if (activeTab === 0) {
      const finalEmailOrMobile = `+${callingCode}${emailOrMobileTrim}`;
      const validateMobile = this.validatePhoneNumber(finalEmailOrMobile);
      if (validateMobile) {
        const { otp } = this.props;
        otp(
          finalEmailOrMobile,
          'mobile',
          () => {
            this.startOtpTimer();
            this.show('otpInput')();
            Helpers.notification.success('Your OTP has been sent.', 'Sent!');
          },
          () => {}
        );
      } else {
        Helpers.notification.error('Incorrect mobile number format!');
      }
    } else {
      const { otp } = this.props;
      otp(
        emailOrMobileTrim,
        'email',
        () => {
          this.show('otpInput')();
          Helpers.notification.success('Your OTP has been sent.', 'Check your email');
        },
        () => {}
      );
    }
  };

  startOtpTimer = () => {
    this.setState({ otpTime: 180 });
    this.interval = setInterval(() => {
      const { otpTime } = this.state;
      if (otpTime <= 1) {
        this.stopOtpTimer();
      } else {
        this.setState({ otpTime: (otpTime - 1) });
      }
    }, 1000);
  }

  stopOtpTimer = () => {
    this.setState({ otpTime: 0 });
    clearInterval(this.interval);
  }

  validatePhoneNumber = mobile => {
    const { countryCode } = this.state;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const country = { code: countryCode };

    // if (process.env.REACT_APP_NODE_ENV !== 'production') {
    //   return true;
    // }

    try {
      if (
        !phoneUtil.isValidNumberForRegion(
          phoneUtil.parse(
            mobile,
            country.code
          ),
          country.code
        )
      ) {
        return false;
      }
    } catch (e) {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return false;
    }
    return true;
  }

  forgot = () => {
    const { reset } = this.props;
    const {
      password, confirmPassword, otp, email, mobile, emailOrMobile, callingCode, activeTab, code,
    } = this.state;
    if (password && confirmPassword && otp) {
      if (
        password.length < 8
        || !/[A-Z]/.test(password)
        || !/[a-z]/.test(password)
        || !/[0-9]/.test(password)
        // eslint-disable-next-line no-useless-escape
        || !/[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]/.test(password)
      ) {
        // g characters: at least 8 digits, uppercase, lowercase, at least 1 number, 1 non-alphanumeric character '),
        Helpers.notification.error('Your password does not meet the listed requirements.');
      } else if (password !== confirmPassword) {
        Helpers.notification.error('Both password fields must match.', 'Confirm your password');
      } else {
        reset(
          {
            code,
            password,
            passwordConfirmation: confirmPassword,
            [activeTab === 0 ? 'mobile' : 'email']: activeTab === 0 ? mobile ? `+${mobile}` : `+${callingCode}${emailOrMobile.trim()}` : email || emailOrMobile.trim(),
          },
          () => {
            this.setState({
              confirmPassword: '',
              otp: '',
              password: '',
            });
            this.show('success')();
          },
          () => {
            this.show('otpInput')();
          }
        );
      }
    } else {
      Helpers.notification.error('Fill in all fields to continue.', 'Incomplete data');
    }
  };

  setPasswordType = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  back = trigger => () => {
    const { history } = this.props;
    history.push('/dashboard', {
      trigger,
    });
  }

  setCountryCode = arg => {
    const countryCode = arg.target.value;
    const callingCode = countries[countryCode].phone;
    this.setState({ callingCode, countryCode });
  }

  verify = () => {
    const { verifyOtp } = this.props;
    const {
      otp, email, mobile, emailOrMobile, callingCode, activeTab,
    } = this.state;
    if ((email || mobile || emailOrMobile) && otp) {
      verifyOtp(
        {
          code: otp,
          [activeTab === 0 ? 'mobile' : 'email']: activeTab === 0 ? mobile ? `+${mobile}` : `+${callingCode}${emailOrMobile.trim()}` : email || emailOrMobile.trim(),
        },
        res => {
          Helpers.notification.success(res.message, 'Verified');
          this.show('passwordInput')();
          this.setState({ code: res.data.code.toString() });
        },
        () => {}
      );
    } else {
      Helpers.notification.error('Fill in all fields to continue.', 'Incomplete data');
    }
  }

  validateOTP = type => e => {
    const { otp } = this.state;
    if (type === 'change') {
      this.setState({ otp: e.target.value.substring(0, 6).trim() });
      if (e.target.value.length > 6) {
        Helpers.notification.info('OTP cannot have more than 6 digits.');
      }
    } else if (otp.length < 5) {
      Helpers.notification.info('OTP cannot have less than 5 digits.', 'OTP too short!');
    } else {
      this.verify();
    }
  }

  render() {
    const {
      password, confirmPassword, otp, showPassword, show, emailOrMobile, countryCode, activeTab, mobile, email, otpTime,
    } = this.state;
    const {
      user: { loading },
    } = this.props;

    if (show.otpInput) {
      return (
        <Page
          title="Forgot Password"
          alignContentMobile="align-left"
          back={() => {
            this.show(null)();
            this.setState({
              confirmPassword: '',
              otp: '',
              password: '',
            });
          }}
          background="illustration-1"
          hasHeader
          hasLogo
          hm="130px 0 0 0"
          lm="50px 0 0 0"
          footer={<></>}
        >
          <div className="heading forgot">Enter OTP</div>
          <div className="subheading forgot">Input One Time Password received</div>

          <div className="forgot-input">
            <Input placeholder="OTP" type="number" required onChange={this.validateOTP('change')} value={otp} autocomplete="new-password" />
          </div>
          { (otpTime >= 1 && otpTime <= 180) && (
            <div className="forgot-otp-resend">
              <p>Resend in</p>
                &nbsp;
              {otpTime}
              {' '}
              seconds
            </div>
          )}

          { otpTime === 0 && (
            <div className="resend-otp-div">
              <div className="resend-otp-button" onClick={this.sendOtp}>
                Resend OTP
              </div>
            </div>
          )}

          <div className="forgot-button">
            <Button disabled={otp === ''} size="large" loading={loading.some(url => url === '/user/otp/verify')} onClick={this.validateOTP('submit')} background="light">SUBMIT</Button>
          </div>
        </Page>
      );
    } if (show.passwordInput) {
      return (
        <Page
          title="Forgot Password"
          alignContentMobile="align-left"
          back={this.show('otpInput')}
          background="illustration-1"
          hasHeader
          hasLogo
          hm="90px 0 0 0"
          lm="50px 0 0 0"
          footer={<></>}
        >
          <div className="heading forgot">Change Password</div>
          <div className="subheading forgot">Set the new password for your account</div>

          <div className="forgot-input password_input_show">
            <Input placeholder="Enter New Password" type={showPassword ? 'text' : 'password'} onChange={this.change('password')} value={password} autocomplete="new-password" />
            <p alt="Show password" className="password_show_icon" onClick={this.setPasswordType}>{showPassword ? 'Hide' : 'Show'}</p>
          </div>

          <div className="forgot-input password_input_show">
            <Input placeholder="Confirm New Password" type={showPassword ? 'text' : 'password'} onChange={this.change('confirmPassword')} value={confirmPassword} autocomplete="confirm-new-password" />
            <p alt="Show password" className="password_show_icon" onClick={this.setPasswordType}>{showPassword ? 'Hide' : 'Show'}</p>
          </div>

          <div className="password-evaluation">
            <div className="item">
              <img src={!(password.length < 8) ? tickCircle : cancel} alt="" />
              <span>Min 8 characters</span>
            </div>
            <div className="item">
              <img src={/[A-Z]/.test(password) ? tickCircle : cancel} alt="" />
              <span>1 uppercase letter</span>
            </div>
            <div className="item">
              <img src={/[a-z]/.test(password) ? tickCircle : cancel} alt="" />
              <span>1 lowercase letter</span>
            </div>
            <div className="item">
              <img src={/[0-9]/.test(password) ? tickCircle : cancel} alt="" />
              <span>1 number</span>
            </div>
            <div className="item">
              <img
                src={
                // eslint-disable-next-line no-useless-escape
                /[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]/.test(password) ? tickCircle : cancel
              }
                alt=""
              />
              <span>1 symbol</span>
            </div>
          </div>

          <div className="forgot-button">
            <Button disabled={confirmPassword === ''} loading={loading.some(url => url === '/user/reset')} size="large" onClick={this.forgot} background="light">SUBMIT</Button>
          </div>
        </Page>
      );
    } if (show.success) {
      return (
        <Successful
          title="Password Changed"
          subtitle="You have successfully reset your password"
          background="illustration-1"
          buttonText={mobile || email ? 'GO TO DASHBOARD' : 'RETURN TO SIGNIN'}
        />
      );
    }

    return (
      <Page
        title="Forgot Password"
        alignContentMobile="align-left"
        back={this.back()}
        background="illustration-1"
        hasHeader
        hasLogo
        hm="130px 0 0 0"
        lm="50px 0 0 0"
        footer={<></>}
      >
        <div className="heading forgot">
          {mobile || email ? 'Reset' : 'Forgot'}
          {' '}
          Password
        </div>
        <div className="subheading forgot">
          Enter your
          {' '}
          {activeTab === 0 ? 'mobile number' : 'email'}
          {' '}
          for the verification process.
          {(mobile || email)
          && (
          <span>
            {' '}
            (
              {activeTab === 0 ? `+${mobile.slice(0, 4)}*****${mobile.slice(-3)}` : `${email.slice(0, 2)}********${email.slice(-5)}`}
            )
          </span>
          )}
        </div>

        <Tabs
          current={num => this.setState({ activeTab: num })}
          tabs={[
            {
              component: (<div key={1} />),
              title: 'Phone Number',
            },
            {
              component: (<div key={2} />),
              title: 'Email',
            },
          ]}
        />

        {activeTab === 0 ? (
          <DoubleInput
            otherClasses="signin-input"
            leftInput={(
              <div className="mobile_code_select">
                <Input
                  placeholder="Mobile"
                  type="select"
                  name="countryCode"
                  id="country_code_select"
                  onChange={this.setCountryCode}
                  value={countryCode}
                  options={[
                    { label: 'NG (+234)', value: 'NG' }, { label: 'US (+1)', value: 'US' },
                  ].concat(countriesKeys.map(country => ({
                    label: `${country} (+${countries[country].phone})`,
                    value: country,
                  })))}
                />
              </div>
            )}
            rightInput={
              <Input placeholder="" onChange={this.change('emailOrMobile')} value={emailOrMobile} type="number" required />
            }
          />
        ) : (
          <div className="forgot-input">
            <Input placeholder="Email" type="email" onChange={this.change('emailOrMobile')} value={emailOrMobile} autocomplete="email" />
          </div>
        )}

        <div className="forgot-button">
          <Button disabled={emailOrMobile === ''} loading={loading.some(url => url === '/user/otp')} size="large" onClick={this.sendOtp} background="light">SEND OTP</Button>
        </div>

        {(mobile || email)
          && (
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip id={`tooltip-${'left'}`}>
                  I have an unused OTP code sent within the last 30 minutes.
                </Tooltip>
              )}
              offset={[0, 0]}
            >
              <div className="footer forgot" onClick={this.show('otpInput')}>
                <FiInfo className="dashboard-info-icon icon-green" />
                {' '}
                <u>I still have a valid OTP</u>
              </div>
            </OverlayTrigger>
          )}
      </Page>
    );
  }
}

export default Forgot;
