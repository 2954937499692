import React from 'react';
import { FiBell } from 'react-icons/fi';
import { Tabs, Pagination } from '.';

class Notification extends React.Component {
  state = {
    activeTab: 0,
    limit: 10,
    loading: false,
    notifications: {
      all: [],
      loans: [],
      repayments: [],
      withdrawals: [],
    },
    offset: 0,
    show: false,
    total: 0,
  };

  dropdownRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.handleGlobalClick);
    this.fetchNotifications();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClick);
  }

  handleGlobalClick = event => {
    const dropdownNode = this.dropdownRef.current;

    if (dropdownNode && !dropdownNode.contains(event.target)) {
      this.setState({ show: false });
    }
  };

  handleDropdownToggle = event => {
    event.stopPropagation();
    this.setState(prevState => ({ show: !prevState.show }));
  };

  fetchNotifications = (limit, offset) => {
    const { getNotifications } = this.props;
    getNotifications(limit, offset, res => {
      this.setState({
        loading: false,
        notifications: this.groupNotifications(res.data.all),
        total: res.data.total,
      });
    });
  };

  groupNotifications = notifications => {
    const grouped = {
      all: notifications,
      loans: notifications.filter(notif => notif.notification === 'loans'),
      repayments: notifications.filter(notif => notif.notification === 'repayments'),
      withdrawals: notifications.filter(notif => notif.notification === 'withdrawals'),
    };
    return grouped;
  };

  handlePagination = selectedPage => {
    const { limit } = this.state;
    const offset = selectedPage * limit;
    this.fetchNotifications(limit, offset);
  };

  render() {
    const {
      show, notifications, total, limit,
    } = this.state;

    return (
      <>
        <div ref={this.dropdownRef}>
          <FiBell
            style={{ cursor: 'pointer', height: '90px', width: '20px' }}
            onClick={this.handleDropdownToggle}
          />
        </div>

        {show && (
          <div
            onClick={e => e.stopPropagation()}
            className='modal-container'
          >
            <div className='notification-center'>Notifications Center</div>

            <Tabs
              current={num => this.setState({ activeTab: num })}
              tabs={[
                {
                  component: <NotificationList data={notifications.all} />,
                  title: 'All',
                },
                {
                  component: <NotificationList data={notifications.loans} />,
                  title: 'Loans',
                },
                {
                  component: <NotificationList data={notifications.repayments} />,
                  title: 'Repayments',
                },
                {
                  component: <NotificationList data={notifications.withdrawals} />,
                  title: 'Withdrawals',
                },
              ]}
            />

            <Pagination
              total={total}
              perPage={limit}
              handlePagination={this.handlePagination}
              current={0}
            />
          </div>
        )}
      </>
    );
  }
}

const NotificationList = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No notifications available</div>;
  }

  return (
    <ul className='notificationList'>
      {data.map((notif, index) => (
        <li
          key={index}
          // style={{
          //   alignItems: 'center',
          //   borderBottom: '1px solid #f1f1f1',
          //   display: 'flex',
          //   padding: '10px 0',
          // }}
        >
          {/* Round Circle Image (Placeholder) */}
          <div
            // style={{
            //   alignItems: 'center',
            //   backgroundColor: '#f1f1f1',
            //   borderRadius: '50%',
            //   display: 'flex',
            //   height: '40px',
            //   justifyContent: 'center',
            //   marginRight: '10px',
            //   minWidth: '40px',
            // }}
            className='notificationList-img'
          >
            <FiBell size={20} />
          </div>

          {/* Notification Content */}
          <div className='notificationContent'>
            <div className='notificationContentSSubject'>{notif.subject}</div>
            <div className='notificationContentBody'>{notif.body}</div>
            <div className='notificationContentDate'>
              {new Date(notif.date).toLocaleString()}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Notification;
