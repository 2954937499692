// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import {
  Page, Banner, Rates, UserIcon
} from '../components';

import carrotUser from '../assets/images/carrot-user.svg';
import carrotEmail from '../assets/images/carrot-email.svg';
import carrotPhone from '../assets/images/carrot-phone.svg';
import arrowLeft from '../assets/images/arrow-left.svg';

import * as Helpers from '../helpers';

const options = {
  day: 'numeric', month: 'long', weekday: 'long', year: 'numeric',
};
class Profile extends React.Component {
  state = {
    windowWidth: 0,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    document.title = 'Profile | Carrot';
    const { user: { user_id }, getUserApplications, application: { user: applications } } = this.props;
    if (applications.length === 0) {
      getUserApplications(user_id);
    }
  }

  componentDidUpdate() {
    const { user: { blacklist }, history } = this.props;
    if (blacklist?.signin) {
      Helpers.notification.error('You are not allowed to use this service.');
      history.push('/dashboard');
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  noop = () => null;

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  back = () => {
    const { history } = this.props;
    history.push('/dashboard');
  }

  out = () => {
    const { logout, history } = this.props;
    logout(history);
  }

  render() {
    const { windowWidth } = this.state;
    const {
      user: userData, application: { user: applications },
    } = this.props;

    return (
      <>
        <Rates windowWidth={windowWidth} userData={userData} />

        <Banner dropdown userData={userData} out={this.out} currentPage="profile" />

        <Page
          title="Profile"
          alignContentMobile="align-left"
          background="illustration-3"
          hm="20px 0 0 0"
          lm="39.2px 0 0 0"
          footer={<></>}
        >
          <div>
            <img src={arrowLeft} className="header-back-icon" id="profile" alt="back" onClick={this.back} />
          </div>

          <div id="profile-user-icon">
            <UserIcon userData={userData} />
          </div>

          <div className="profile-box">
            <div className="profile-text-margin profile-container">
              <img
                src={carrotUser}
                alt="User profile"
              />
              <span className="profile-text">{userData && userData.firstname && Helpers.capitalizeFirstLetter3(`${userData.firstname} ${userData.lastname}`)}</span>
            </div>
            <hr className="phorizontal-line" />

            {userData && userData.email ? (
              <>
                <div className="profile-text-margin profile-container">
                  <img
                    src={carrotEmail}
                    alt="User profile"
                  />
                  <span className="profile-text">{userData && userData.email}</span>
                </div>
                <hr className="phorizontal-line" />
              </>
            ) : ''}

            {userData && userData.mobile ? (
              <>
                <div className="profile-text-margin profile-container">
                  <img
                    src={carrotPhone}
                    alt="User profile"
                  />
                  <span className="profile-text" id="profile-number">{userData && userData.mobile}</span>
                </div>
                <hr className="phorizontal-line" />
              </>
            ) : '' }

            <div className="profile-text-margin profile-container">
              <div>Connected</div>

              <div id="profile-connected-apps">
                {applications.map((vendor, i) => (
                  <div className="item" key={`vendor-${i}`}>
                    <img src={/^http/.test(vendor.images.logo) ? vendor.images.logo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(vendor.images.logo)}`} alt="" className="vendor-logo" />
                  </div>
                ))}
              </div>
            </div>
            <hr className="phorizontal-line" id="under-logo" />
            <div className="profile-text-margin">
              Joined
              {' '}
              {userData && userData.date && new Date(userData.date).toLocaleDateString(undefined, options)}
            </div>
          </div>
        </Page>
      </>
    );
  }
}

export default Profile;
