import React from 'react';

import {
  Button, Page, Input, Successful
} from '../../components';

import * as Helpers from '../../helpers';
import dollarSign from '../../assets/images/dollar-sign.png';
import nairaSign from '../../assets/images/naira-sign.png';
import tickCircle from '../../assets/images/tick-circle.svg';

class PaystackWithdrawal extends React.Component {
  state = {
    details: {},
    name: '',
    processor_id: 0,
    sendToAccount: 'new',
    success: false,
    successMessage: '',
    verified: false,
  };

  componentDidUpdate(_, prevState) {
    const { details, processor_id } = this.state;
    if (JSON.stringify(details) !== JSON.stringify(prevState.details)) {
      const { verifyWithdrawalDetails } = this.props;

      // TODO: Whether or not account details for a
      // processor should be verified should be a
      // processor-level config perhaps called `verify`
      if (processor_id === 3) {
        this.setState({ verified: true });
      } else if (this.validate()) {
        verifyWithdrawalDetails(details, processor_id, response => {
          const { data: { account_name } } = response.data;
          this.setState({ name: account_name, verified: true });
        });
      }
    }
  }

  change = slug => e => {
    const { details, processor_id } = this.state;
    this.setState({
      details: processor_id === 1 && slug === 'account_number'
        ? { ...details, [slug]: e.target.value.substring(0, 10) }
        : { ...details, [slug]: e.target.value },
      verified: false,
    });
  };

  withdraw = () => {
    const {
      details, verified, processor_id,
    } = this.state;
    const {
      amount, withdraw,
    } = this.props;

    if (!this.validate()) {
      return Helpers.notification.error(
        'The bank details you entered are incorrect.'
      );
    }

    if (!verified) {
      return Helpers.notification.error(
        'We could not verify the details you input. Try again.'
      );
    }

    return withdraw(
      amount,
      details,
      processor_id,
      Helpers.token.get('user:application_id'),
      res => {
        this.setState({
          success: true,
          successMessage: res.message,
        });
      }
    );
  }

  validate = () => {
    const { details, processor_id } = this.state;
    const { withdrawal: { processors } } = this.props;
    let invalidParam = '';
    processors.map(processor => {
      if (processor.processor_id === `${processor_id}`) {
        processor.requirements.every(requiredParam => {
          if (!details[requiredParam.slug] || typeof details[requiredParam.slug] === 'undefined') {
            invalidParam = requiredParam.slug;
            return false;
          }

          if (
            requiredParam.type === 'number' && (!/^\d+$/.test(details[requiredParam.slug])
            || details[requiredParam.slug].length < 10)) {
            invalidParam = requiredParam.slug;
            return false;
          }

          return true;
        });
      }

      return null;
    });

    return !invalidParam;
  }

  sendToAccount = e => {
    const { processor_id } = this.state;
    const { withdrawal: { processors } } = this.props;
    const sendToAccount = e.target.value;

    if (sendToAccount === 'old') {
      let details = {};
      processors.map(processor => {
        if (processor.processor_id === `${processor_id}`) {
          details = processor.details;
        }
        return null;
      });

      this.setState({
        details,
      });
    } else {
      this.setState({
        details: {},
      });
    }

    this.setState({
      sendToAccount,
      verified: false,
    });
  }

  getImage = slug => {
    switch (slug) {
    case 'naira-bank':
      return nairaSign;
    case 'auto-dollar-bank':
      return dollarSign;
    case 'manual-dollar-bank':
      return dollarSign;
    default:
      return '';
    }
  }

  setProcessor = slug => {
    switch (slug) {
    case 'naira-bank':
      this.setState({ processor_id: 1 });
      break;
    case 'auto-dollar-bank':
      this.setState({ processor_id: 3 });
      break;
    case 'manual-dollar-bank':
      this.setState({ processor_id: 3 });
      break;
    default:
      this.setState({ processor_id: 0 });
    }
  }

  back = () => {
    const { back } = this.props;
    back();
  }

  render() {
    const {
      details,
      sendToAccount,
      verified, name, success,
      processor_id,
      successMessage,
    } = this.state;
    const {
      user: { loading }, withdrawal: { processors }, amount, history,
    } = this.props;

    if (success) {
      return (
        <Successful
          title="Withdrawal"
          subtitle={successMessage || `We have begun the process of transferring $${Helpers.math.divide(amount, 100)} to your bank account provided`}
          action="Go to Dashboard"
          background="illustration-3"
          history={history}
        />
      );
    }
    return (
      <Page
        title="Withdraw"
        alignContentMobile="align-left"
        back={this.back}
        background="illustration-3"
        hasHeader
        hasLogo
        hm="20px 0 0 0"
        lm="39.2px 0 0 0"
        footer={<></>}
      >
        <div className="heading disburseOptions">
          Where would you like your money sent to?
        </div>

        <div className="subheading disburseOptions">
          Please choose from one of our local or international disbursement options.
        </div>

        <div className="disburse-options">
          {
              processors.map((processor, i) => processor.slug !== 'auto-dollar-bank' && (
                <button key={i} className="item highlight" onClick={() => this.setProcessor(processor.slug)}>
                  <img src={this.getImage(processor.slug)} alt="" className="currency-image" />
                  {processor.display_name}
                  <img className="tick" src={tickCircle} alt="" />
                </button>
              ))
            }
        </div>

        <div className="disburseOptions-input">
          <Input
            placeholder="Select Account"
            type="select"
            onChange={this.sendToAccount}
            value={sendToAccount}
            options={[{
              label: 'Use Exisiting Account',
              value: 'old',
            }, {
              label: 'Add New Account',
              value: 'new',
            }]}
            disabled={processor_id === 0}
          />
        </div>

        { processor_id !== 0
          && processors.map((processor, i) => processor.processor_id === `${processor_id}`
            && processor.requirements.map((requirement, j) => (
              <div key={`${i}//${j}`} className="disburseOptions-input">
                <Input
                  key={`${i}//${j}`}
                  placeholder={requirement.placeholder}
                  type={requirement.type}
                  onChange={this.change(requirement.slug)}
                  value={details[requirement.slug] || ''}
                  options={requirement.options && requirement.options.map(option => ({ label: option.name, value: option.code }))}
                />
              </div>
            )))}

        {verified && (
        <div className="disburseOptions-input">
          <Input onChange={() => {}} placeholder="Account Name" value={name} />
        </div>
        )}

        <div className="disburseOptions-button">
          <Button
            loading={loading.some(url => url === '/withdrawal')}
            disabled={!verified}
            onClick={this.withdraw}
            size="large"
            background="light"
          >
            NEXT
          </Button>
        </div>
      </Page>
    );
  }
}

export default PaystackWithdrawal;
